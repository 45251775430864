import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConstants } from 'src/app/constants';
import { cleanObject } from 'src/functions/common-functions';

@Injectable({
  providedIn: 'root',
})
export class ApiSiniestroEstandarService {
  _baseURL: string;
  path: string;
  activeEdit: boolean;
  preview: boolean;
  copy: boolean;
  constructor(private http: HttpClient) {
    this._baseURL = AppConstants.apiBienestar;
    // this.path = this._baseURL + '/v1/monitor/reembolso';
  }

  getToken() {
    return localStorage.getItem('userToken');
  }

  listadoSiniestroEstandar(p): Observable<any> {
    const url = `${this._baseURL}/v1/siniestro-estandar`;

    let params = cleanObject(p);

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
        params,
      })
      .pipe(map((data) => data));
  }

  copyRegla(id): Observable<any> {
    const url = `${this._baseURL}/v1/siniestro-estandar/${id}/copiar`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  detalleSiniestro(id): Observable<any> {
    const url = `${this._baseURL}/v1/siniestro-estandar/${id}`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  nuevoSiniestro(json): Observable<any> {
    const url = `${this._baseURL}/v1/siniestro-estandar`;

    return this.http
      .post(url, json, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  listSexo(): Observable<any> {
    const url = `${this._baseURL}/catalogos/sexos`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  listRamos(): Observable<any> {
    const url = `${this._baseURL}/catalogos/ramos`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  listCoberturas(p): Observable<any> {
    const url = `${this._baseURL}/catalogos/coberturas/?ramos=${p}`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  listTipoSumaAsegurada(): Observable<any> {
    const url = `${this._baseURL}/catalogos/tipos-suma-asegurada`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  listTipoTramite(p): Observable<any> {
    const url = `${this._baseURL}/gestor-tramites/catalogos/tipo-tramite?ramos=${p}`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  listTipoSiniestro(): Observable<any> {
    const url = `${this._baseURL}/catalogos/tipo-siniestro`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  listCausas(p): Observable<any> {
    const url = `${this._baseURL}/gestor-tramites/catalogos/causas?ramos=${p}`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  listTipoAtencion(): Observable<any> {
    const url = `${this._baseURL}/gestor-tramites/catalogos/tipo-atencion`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  listTipoDiagnosticos(): Observable<any> {
    const url = `${this._baseURL}/gestor-tramites/catalogos/tipo-diagnostico`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  areasCatalogo(): Observable<any> {
    const url = `${this._baseURL}/v1/siniestro-estandar/catalogos`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  deleteSiniestro(id): Observable<any> {
    const url = `${this._baseURL}/v1/siniestro-estandar/${id}`;

    return this.http
      .delete(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  activarSiniestro(id): Observable<any> {
    const url = `${this._baseURL}/v1/siniestro-estandar/${id}/apagar-encender `;

    return this.http
      .post(
        url,
        {},
        {
          headers: new HttpHeaders({
            Accept: '*/*',
            Authorization: this.getToken(),
          }),
        }
      )
      .pipe(map((data) => data));
  }

  catalogoDinamico(key, id): Observable<any> {
    const url = `${this._baseURL}/v1/siniestro-estandar/catalogo/${key}?id=${id}`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  ramoBase(id): Observable<any> {
    const url = `${this._baseURL}/v1/siniestro-estandar/${id}/catalogo/ramos`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  catalogoCobertura(p): Observable<any> {
    const url = `${this._baseURL}/catalogos/coberturas?ramos=${p}`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  catalogoConeptoValoracion(p, r): Observable<any> {
    const url = `${this._baseURL}/catalogos/conceptos-valoracion-coberturas?coberturas=${p}&ramo=${r}`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }
}
